// Generated by Framer (34a0b35)

import * as React from "react";
import { motion, LayoutGroup } from "framer-motion";
import { useOnVariantChange, useActiveVariantCallback, Image, transformTemplate, addFonts, withCSS, addPropertyControls, ControlType, cx, useAddVariantProps, useVariantState, CycleVariantState, Container, getFonts, Stack, useHydratedBreakpointVariants, removeHiddenBreakpointLayers, SSRVariants, GeneratedComponentContext } from "framer";
import { useRandomID } from "https://framer.com/m/framer/randomID.js@^2.0.0";
import HeroSignUpVideo from "../canvasComponent/w17YgMAal";
import FeaturesOffset from "../canvasComponent/KKr1OZxQT";
import FooterSocial from "../canvasComponent/rRcLhztZk";
const HeroSignUpVideoFonts = getFonts(HeroSignUpVideo);
const FeaturesOffsetFonts = getFonts(FeaturesOffset);
const FooterSocialFonts = getFonts(FooterSocial);

const cycleOrder = ["WQLkyLRf1", "IungXxfZL", "cKLbMaRoq"];

const breakpoints = {"WQLkyLRf1": "(min-width: 1200px)", "IungXxfZL": "(min-width: 810px) and (max-width: 1199px)", "cKLbMaRoq": "(max-width: 809px)"}

const isBrowser = typeof document !== "undefined";

const variantClassNames = {"WQLkyLRf1": "framer-v-72rtr7", "IungXxfZL": "framer-v-17qzmkb", "cKLbMaRoq": "framer-v-11c781o"};

if (isBrowser) {
                    removeHiddenBreakpointLayers("WQLkyLRf1", breakpoints, variantClassNames);
            }

const humanReadableVariantMap = {"Desktop": "WQLkyLRf1", "Tablet": "IungXxfZL", "Phone": "cKLbMaRoq"};

const transitions = {"default": {"duration": 0}};

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ style: externalStyle = {}, className, width, height, layoutId, variant: outerVariant = "WQLkyLRf1", ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const [baseVariant, hydratedBaseVariant] = useHydratedBreakpointVariants(variant, breakpoints, false);
const gestureVariant = undefined;
const transition = transitions.default;

const variantProps = React.useMemo(() => ({"IungXxfZL": {"WQLkyLRf1": {"__contentWrapperStyle": {"width": "100%", "height": "auto", "padding": "0px 0px 0px 0px"}, "transformTemplate": undefined}, "nHHbILkSd": {"variant": "hRsCb0g2n", "style": {"width": "100%", "height": "100%"}}, "nHHbILkSd-container": {"transformTemplate": undefined}, "aUcrlqgZV": {"variant": "eFoh7iu41", "style": {"width": "100%"}}, "aUcrlqgZV-container": {"transformTemplate": undefined}, "iTcD2nAFE": {"variant": "btlErBX5q", "style": {"width": "100%"}}, "iTcD2nAFE-container": {"transformTemplate": undefined}}, "cKLbMaRoq": {"WQLkyLRf1": {"__contentWrapperStyle": {"width": "100%", "height": "auto", "padding": "0px 0px 0px 0px"}, "transformTemplate": undefined}, "nHHbILkSd": {"variant": "nKrq_EpVA", "style": {"width": "100%"}}, "nHHbILkSd-container": {"transformTemplate": undefined}, "aUcrlqgZV": {"variant": "QtLKtj0mJ", "style": {"width": "100%"}}, "aUcrlqgZV-container": {"transformTemplate": undefined}, "iTcD2nAFE": {"variant": "BGwNcg1ht", "style": {"width": "100%"}}, "iTcD2nAFE-container": {"transformTemplate": undefined}}}), []);

const addVariantProps = useAddVariantProps(hydratedBaseVariant, gestureVariant, variantProps);

const defaultLayoutId = useRandomID();

const { pointerEvents, ...style } = externalStyle

return (<GeneratedComponentContext.Provider value={{ primaryVariantId: "WQLkyLRf1", variantProps: variantProps, variantClassNames: variantClassNames}}>
<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div data-framer-generated className="framer-0yCua" style={{"display": "contents", "pointerEvents": pointerEvents ?? undefined}}>
<SSRVariants id="WQLkyLRf1"><Stack {...restProps} className={cx("framer-72rtr7", className)} style={{...style}} name="Desktop" direction="vertical" distribution="start" alignment="center" gap={0} __fromCanvasComponent={true} __contentWrapperStyle={{"width": "100%", "height": "auto", "padding": "0px 0px 0px 0px"}} ref={ref} {...addVariantProps("WQLkyLRf1")}><Container className="framer-re4or3-container" {...addVariantProps("nHHbILkSd-container")}><SSRVariants id="nHHbILkSd"><HeroSignUpVideo width="100%" height="100%" layoutId="nHHbILkSd" id="nHHbILkSd" variant="ycOr1XthY" style={{"width": "100%", "height": "100%"}} {...addVariantProps("nHHbILkSd")}/></SSRVariants></Container><Container className="framer-10wypvt-container" {...addVariantProps("aUcrlqgZV-container")}><SSRVariants id="aUcrlqgZV"><FeaturesOffset width="100%" height="100%" layoutId="aUcrlqgZV" id="aUcrlqgZV" variant="TiphoQ6XS" style={{"width": "100%"}} {...addVariantProps("aUcrlqgZV")}/></SSRVariants></Container><Container className="framer-gsncwg-container" {...addVariantProps("iTcD2nAFE-container")}><SSRVariants id="iTcD2nAFE"><FooterSocial width="100%" height="100%" layoutId="iTcD2nAFE" id="iTcD2nAFE" variant="tpSgIeMh4" style={{"width": "100%"}} {...addVariantProps("iTcD2nAFE")}/></SSRVariants></Container></Stack></SSRVariants>
</motion.div>
</LayoutGroup>
</GeneratedComponentContext.Provider>)

});

const css = [".framer-0yCua [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none;}", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-0yCua .framer-72rtr7 { position: relative; overflow: hidden; width: 1200px; height: min-content; background-color: #ffffff; }", ".framer-0yCua .framer-re4or3-container { position: relative; width: 100%; height: 760px; flex: none; }", ".framer-0yCua .framer-10wypvt-container { position: relative; width: 100%; height: auto; flex: none; }", ".framer-0yCua .framer-gsncwg-container { position: relative; width: 1200px; height: auto; flex: none; }", "@media (min-width: 1200px) { .framer-0yCua .hidden-72rtr7 { display: none !important; } }", "@media (min-width: 810px) and (max-width: 1199px) { .framer-0yCua .hidden-17qzmkb { display: none !important; } .framer-0yCua .framer-72rtr7 { width: 810px; height: min-content; } .framer-0yCua .framer-re4or3-container { width: 100%; height: 1007px; right: auto; bottom: auto; left: auto; top: auto; flex: none; aspect-ratio: unset; } .framer-0yCua .framer-10wypvt-container, .framer-0yCua .framer-gsncwg-container { width: 100%; height: auto; right: auto; bottom: auto; left: auto; top: auto; flex: none; aspect-ratio: unset; }}", "@media (max-width: 809px) { .framer-0yCua .hidden-11c781o { display: none !important; } .framer-0yCua .framer-72rtr7 { width: 390px; height: min-content; } .framer-0yCua .framer-re4or3-container, .framer-0yCua .framer-10wypvt-container, .framer-0yCua .framer-gsncwg-container { width: 100%; height: auto; right: auto; bottom: auto; left: auto; top: auto; flex: none; aspect-ratio: unset; }}"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 1459
 * @framerIntrinsicWidth 1200
 * @framerCanvasComponentVariantDetails {"propertyName": "variant", "data": {"default": {"layout": ["fixed", "auto"]}, "IungXxfZL": {"layout": ["fixed", "auto"]}, "cKLbMaRoq": {"layout": ["fixed", "auto"]}}}
 * @framerResponsiveScreen
 */
const FrameraugiA20Il: React.ComponentType<Props> = withCSS(Component, css) as typeof Component;
export default FrameraugiA20Il;

FrameraugiA20Il.displayName = "Home";

FrameraugiA20Il.defaultProps = {"width": 1200, "height": 1459};

addFonts(FrameraugiA20Il, [...HeroSignUpVideoFonts, ...FeaturesOffsetFonts, ...FooterSocialFonts]);